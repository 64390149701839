import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  FaqsList,
} from 'layouts';
import {
  Banner,
  Breadcrumbs, FormSelect,
} from 'components';
import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_FAQS.TITLE,
    absolute_slug: PAGES.ABOUT_FAQS.PATH,
  },
];

class AboutFAQSPage extends PureComponent {
  constructor(props) {
    super(props);
    const CATEGORIES = FORMAT_GRAPH_DATA(props.data.allFaqCategories);
    this.state = {
      selectedCat: CATEGORIES[0].api_id,
      categories: CATEGORIES,
      faqs: FORMAT_GRAPH_DATA(props.data.allFaqs),
    };
  }

  render() {
    const { categories, faqs, selectedCat } = this.state;
    return (
      <Layout page="about/faqs">

        <Helmet
          title="Frequently Asked Questions | Haka Tours NZ"
          meta={[
            {
              name: 'description', content: `You’ll find answers to a range of common questions about your booking and joining a New Zealand tour,
              but if you can’t find what you’re looking for please don’t hesitate to contact us via email or online chat or simply give us a bell - we’re here to help!`,
            },
            { name: 'og:title', content: 'Frequently Asked Questions | Haka Tours NZ' },
            {
              name: 'og:description', content: `You’ll find answers to a range of common questions about your booking and joining a New Zealand tour,
              but if you can’t find what you’re looking for please don’t hesitate to contact us via email or online chat or simply give us a bell - we’re here to help!`,
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/faqs/` }]}
        />

        <Banner backgroundImage="https://hakatours-staging.imgix.net/000/faqs_banner.jpg?fit=crop&auto=format">
          <h2 className="c-heading c-heading--h0">Faqs</h2>
        </Banner>
        <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
        <section className="l-section">
          <div className="l-container">
            <Breadcrumbs list={BREADCRUMBS} />
          </div>
          <div className="l-container l-container--small u-text--center">
            <h1 className="c-heading c-heading--h2">Frequently Asked Questions</h1>
            <p>You’ll find answers to a range of common questions below, but if you can’t find what you’re looking for please don’t hesitate to contact us via email or online chat or simply give us a bell - we’re here to help!
            </p>
          </div>
        </section>
        <section className="l-faqs l-section">
          <div className="l-container">
            <aside className="l-faqs__categories">
              <h4 className="c-heading c-heading--h4">Pick your topic</h4>
              <form action="">
                <FormSelect
                  id="category"
                  name="category"
                  onChange={(option) => {
                    if (option && option.category) {
                      this.setState({
                        selectedCat: option.category,
                      });
                    }
                  }}
                  options={categories}
                />
              </form>
              <ul>
                {categories.map(cat => (
                  <li key={cat.api_id}>
                    <button
                      type="button"
                      className={`${cat.api_id === selectedCat ? 'is-active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          selectedCat: cat.api_id,
                        });
                      }}
                    >{cat.name}
                    </button>
                  </li>
                ))}
              </ul>
            </aside>
            <div>
              {categories.filter(cat => cat.api_id === selectedCat).map(cat => (
                <FaqsList
                  title={cat.name}
                  key={cat.api_id}
                  list={faqs.filter(faq => faq.faq_category_id === selectedCat)}
                />
              ))
              }
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

AboutFAQSPage.propTypes = {
  data: PropTypes.any.isRequired,
};

export const query = graphql`
  query {
    allFaqs {
      edges {
        node {
          id
          api_id
          question
          answer
          faq_category_id
        }
      }
    }

    allFaqCategories {
      edges {
        node {
          id
          api_id
          name
        }
      }
    }
  }
`;

export default AboutFAQSPage;
